import { GridApi, GridOptions, GridReadyEvent } from '@ag-grid-community/core';
import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  computed,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest, firstValueFrom } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LoaderService } from '@logic-suite/shared/components/loader';
import { Highcharts_i18n, LanguageService } from '@logic-suite/shared/i18n';
import { TableComponent, TableConfig, TableRow, TableService } from '@logic-suite/shared/table';
import { errorToString } from '@logic-suite/shared/utils';

import { toSignal } from '@angular/core/rxjs-interop';
import DOMPurify from 'dompurify';
import { AssetNode, AssetTreeService } from '../../nav/asset-tree';
import { TooltipRow } from './tooltip-row';
import { WidgetGraphTooltipComponent } from './widget-graph-tooltip.component';
import { WidgetRepository } from './widget-repository.service';
import { WidgetComponent } from './widget.component';

type ChartState = {
  selected?: string;
  state: { id: string; previous: boolean; current: boolean }[];
};

export type GraphPredicate = {
  x: number;
  y?: number;
  point: Highcharts.Point;
  row: TableRow;
  seriesName: string;
  series: Highcharts.Series;
};

/**
 * This is what every widget component should extend from.
 *
 * Although we have a `WidgetComponent`, that component is a GUI wrapper
 * which should be present in a widget's template, but it is not a Widget in
 * itself. The `WidgetComponent` brings common GUI elements for all widgets,
 * but this class brings the common functionality to be inherited by all widgets.
 */
@Directive()
export abstract class AbstractWidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly el = inject(ElementRef);
  protected readonly lang = inject(LanguageService);
  protected readonly translate = inject(TranslateService);
  protected readonly assetTree = inject(AssetTreeService);
  protected readonly route = inject(ActivatedRoute);
  protected readonly router = inject(Router);
  protected readonly repository = inject(WidgetRepository);
  protected readonly loader = inject(LoaderService);
  protected readonly tableService = inject(TableService);
  protected readonly destroyRef$ = inject(DestroyRef);
  private readonly viewContainerRef = inject(ViewContainerRef);

  @HostBinding('class.widget') private __isWidget = true;
  @ViewChild(TableComponent, { static: false }) __table?: TableComponent;

  self = this; // A reference for the component class for this widget
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputData: any; // Any input the individual widgets might require. This will come from the route definition
  currentLanguage = signal(this.lang.current);

  /** The query parameters used to persist state between view modes */
  get params() {
    return this.assetTree.calcQueryParams(this.assetTree.getSelectedNode());
  }

  /** Holds a reference to the inner `WidgetComponent`. Every component inheriting from this, must have this as a child element. */
  widgetComponent = viewChild.required<WidgetComponent>('widget');
  private resizeObserver = new ResizeObserver((entries) => {
    this.size$.next({ width: entries[0].contentRect.width, height: entries[0].contentRect.height });
  });
  private size$ = new BehaviorSubject({ width: 0, height: 0 });
  currentWidgetSize = toSignal(this.size$, { initialValue: { width: 0, height: 0 } });
  width = computed(() => this.currentWidgetSize().width);
  height = computed(() => this.currentWidgetSize().height);

  /** Holds all the subscriptions for this component, so we can easily cleanup on component destruction */
  subscriptions: Subscription[] = [];

  /** Common http load indicator. Used to turn spinners on/off */
  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoading = toSignal(this.isLoading$);
  _reload = true;

  /** Common property to indicate whether data is available or not. This will display a message on top of graphs */
  hasData = signal(false);
  /** Common property to indicate if the component has an error. Errors will not display graphs, only the error */
  hasError = signal(false);

  /** Helper indicator for subscriptions to check if this component is still active before referencing UI objects */
  isMounted = signal(false);
  isDestroyed = signal(false);

  /** Common property to indicate whether datafetch was erroneous or not */
  error = signal<string | undefined>(undefined);
  errorDetails = signal<string | undefined>(undefined);
  chart?: Highcharts.Options;
  Highcharts: typeof Highcharts = Highcharts;
  chartRef$ = new ReplaySubject<Highcharts.Chart>(1);
  chartObj = toSignal(
    this.chartRef$.pipe(
      filter((c) => !!c),
      map((c) => c as Highcharts.Chart),
    ),
    { initialValue: undefined },
  );
  chartCallback = (chart: Highcharts.Chart) => this.chartRef$.next(chart);

  backTitle = 'Settings';

  chartState: ChartState = { selected: undefined, state: [] };
  shiftDown = false;

  /** Config for AG-Grid */
  gridApi = signal<GridApi | undefined>(undefined);
  gridOptions: GridOptions = {
    gridId: this.widgetName,
    onGridReady: (event: GridReadyEvent) => this.gridApi.set(event.api),
    grandTotalRow: 'bottom',
    getRowId: (row: TableRow) => `${this.createHash(row.data)}`,
    onCellMouseOver: (params) => this.tableHover(params.data),
    onCellMouseOut: (params) => this.reset(params.data),
  };

  /** @deprecated Config object for lib-table component */
  tableConfig: TableConfig = {
    id: this.widgetName,
    columns: [],
    hidden: [],
    onHighlight: this.tableHover.bind(this),
    onReset: this.reset.bind(this),
  };
  /** DataSource for `AG-Grid` or `lib-table` component */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tableData = signal<any[]>([]);

  get node(): AssetNode {
    return this.assetTree.getSelectedNode();
  }
  routePath = '';

  languageCode: string;
  isTooltipShared: any = undefined;
  currentTabIndex = 0;

  /**
   * Sniff the view mode of this widget.
   * Widgets can be viewed as a part of a grid of widgets, or standalone
   * as a view of it's own.
   *
   * @returns `true` if the current view state is fullscreen. `false` if not.
   */
  fullscreen$ = new BehaviorSubject<boolean>(false);
  isFullscreen = toSignal<boolean>(this.fullscreen$); // This *will* come in late. Do not trust this on init

  static getName() {
    return 'No Name';
  }

  get fullWidgetName() {
    return this.widgetName;
  }

  get widgetName() {
    return this.el.nativeElement.tagName.toLowerCase();
  }

  /**
   * Function to compare a datapoint to a row in the table
   *
   * Used to determine which row to highlight when hovering the graph, or which point in the graph
   * to show tooltip for when hovering the table.
   * Since each widget has its own property structure, this function should be overridden.
   * @param predicate
   * @returns true if table row matches point
   */
  graphPredicate = (predicate: GraphPredicate) => predicate.x == predicate.row.timestamp;

  /**
   *
   * @param el the element reference which should become a widget
   */
  constructor() {
    this.el.nativeElement.classList.add('draggable');
    this.languageCode = this.lang.current;
    this.destroyRef$.onDestroy(() => {
      this.isDestroyed.set(true);
      this.isMounted.set(false);
    });
  }

  ngOnInit() {
    this.resizeObserver.observe(this.el.nativeElement);
    this.isMounted.set(true);

    // Reset hasError each time widget starts loading
    this.subscriptions.push(
      this.isLoading$.subscribe((val) => {
        if (val) {
          this.hasError.set(false);
          this.error.set(undefined);
          this.errorDetails.set(undefined);
        }
      }),
    );

    // Handle language changes
    const opts = Highcharts.getOptions();
    Highcharts.setOptions(
      Object.assign(opts, { lang: Object.assign({}, opts.lang, Highcharts_i18n[this.lang.current]) }),
    );
    this.subscriptions.push(
      this.lang.onLangChange.pipe(filter((lng) => !!lng)).subscribe(async (lng: LangChangeEvent) => {
        Highcharts.setOptions(Object.assign(opts, { lang: Object.assign({}, opts.lang, Highcharts_i18n[lng.lang]) }));
        this.languageCode = lng.lang;
        const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
        this.onLangChange(lng, chart);

        if (this.gridApi() && !this.gridApi()?.isDestroyed()) {
          this.gridApi()!.refreshHeader();
          this.gridApi()!.refreshCells();
        }
      }),
    );

    // Set input data from route
    this.subscriptions.push(
      combineLatest([this.route.data, this.assetTree.nodeSelection$]).subscribe(([data, node]) => {
        if (!!data && !this.inputData) {
          this.inputData = data;
        }
        // Check if we should redirect to a synonym route
        if (data.synonym && data.redirectOn.includes(node.type)) {
          firstValueFrom(this.repository.route(data.synonym)).then((r) => {
            if (r)
              this.router.navigate(['..', r], {
                relativeTo: this.route,
                queryParamsHandling: 'preserve',
              });
          });
        }
      }),
    );

    // Toggle load spinner in fullscreen
    this.subscriptions.push(
      this.isLoading$.subscribe((val) => {
        if (this.isFullscreen()) {
          if (val) {
            this.loader.startLoad();
          } else {
            this.loader.forceStopAll();
          }
        }
      }),
    );

    // Detect the widget display state (widget/fullscreen)
    this.subscriptions.push(
      this.repository
        .route(this.fullWidgetName)
        .pipe(filter((r) => !!r))
        .subscribe((route) => {
          this.routePath = route;
          this.fullscreen$.next(this.router.url.indexOf(route) > -1);
          this.el.nativeElement.classList.toggle('fullscreen', this.isFullscreen());
        }),
    );
  }

  /**
   *
   */
  ngAfterViewInit() {
    this.onResize();
  }

  afterRender() {
    // setTimeout(() => this.onResize(), 500);
  }

  /**
   * Common cleanup. Requires that every component inheriting from this
   * places their subscriptions in this.`subscriptions` array.
   */
  ngOnDestroy() {
    this.gridApi()?.destroy();
    this.gridApi.set(undefined);
    this.resizeObserver.disconnect();
    this.isMounted.set(false);
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected abstract loadData(...args: any): Observable<any>;

  /**
   * Generic function to generate Highcharts tooltips
   */
  generateTooltip(rows: TooltipRow[], header?: string | TooltipRow, footer?: string | TooltipRow): string {
    // Create a tooltip component and render it
    const tooltip = this.viewContainerRef.createComponent(WidgetGraphTooltipComponent);
    tooltip.setInput('header', header);
    tooltip.setInput('rows', rows);
    tooltip.setInput('footer', footer);
    tooltip.changeDetectorRef.detectChanges();

    // Get the sanitized HTML and remove the tooltip component
    const html = tooltip.location.nativeElement.innerHTML.replaceAll('"', `'`);
    const sanitized = DOMPurify.sanitize(html);
    this.viewContainerRef.clear();
    return sanitized;
  }

  /**
   * Handles all errors regarding loading data into this widget
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorHandler(err: any) {
    this.isLoading$.next(false);
    this.hasData.set(false);
    this.hasError.set(true);
    this.errorDetails.set(undefined);
    const message = errorToString(err)
      .replace(/<[^>]*>/gi, ' ')
      .replace(/\s+/gi, ' ')
      .trim()
      .replace('Server Error Server Error.', 'Server Error: ');
    switch (err.status) {
      case 401:
      case 403:
        this.error.set('You are not authorized to view this data');
        break;
      case 404:
        this.error.set('The data you are looking for does not exist');
        this.errorDetails.set(message);
        break;
      case 499:
        this.error.set('An unknown server error occurred. Please notify Noova support');
        this.errorDetails.set(message);
        break;
      case 500:
        this.error.set('An internal server error occurred. Please notify Noova support');
        this.errorDetails.set(message);
        break;
      case 504:
        this.error.set('The server took too long to respond. Please try again later.');
        break;
      default:
        this.error.set(err.statusText + ': ' + message);
    }
  }

  /**
   * Generic function to force highcharts to redraw after any layout changes has been made
   */
  async onResize() {
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) chart.reflow();
  }

  getSeries(id: string): Highcharts.Series {
    return this.chartObj()?.get(id) as Highcharts.Series;
  }

  /**
   * Called when language changes.
   * Please try to do chart changes in the highcharts config before you resort to implement this method
   */
  onLangChange(lng: LangChangeEvent, chart?: Highcharts.Chart) {
    this.currentLanguage.set(lng.lang);
    if (chart && this.hasData()) {
      // The global highcharts language object is already updated
      chart.redraw(false);
    }
  }

  tabChanged($event: MatTabChangeEvent) {
    this.backTitle = $event.tab.textLabel;
    this.currentTabIndex = $event.index;
  }

  /**
   * Invoked when hovering over graph. Will ask the graphPredicate to identify the tablerow
   * belonging to this data point, and highlight it
   * @returns
   */
  graphMouseOver() {
    const self = this;
    return function () {
      self.tableData().forEach((i: TableRow) => (i._active = false)); // THERE CAN BE ONLY ONE
      const item = self.tableData().find((i: TableRow) =>
        self.graphPredicate({
          row: i,
          x: this.x,
          y: this.y,
          point: this,
          seriesName: this.series.name,
          series: this.series,
        }),
      );
      if (item) {
        item._active = true;
        self.__table?.markAsDirty();
        document.querySelector(`[row-id="${self.createHash(item)}"]`)?.classList.add('ag-row-hover');
      }
    };
  }

  /**
   * Invoked when hovering graph ends
   * @returns
   */
  graphMouseOut() {
    const self = this;
    return function () {
      const item = self.tableData().find((i: TableRow) =>
        self.graphPredicate({
          row: i,
          x: this.x,
          y: this.y,
          point: this,
          seriesName: this.series.name,
          series: this.series,
        }),
      );
      if (item) {
        item._active = false;
        self.__table?.markAsDirty();
        document.querySelector(`[row-id="${self.createHash(item)}"]`)?.classList.remove('ag-row-hover');
      }
    };
  }

  /**
   * Invoked when hovering a table row. Will ask the graphPredicate to identify the graph point
   * belonging to this row, and show tooltip for it.
   * @param row
   */
  async tableHover(row: TableRow) {
    if (!row) return;
    this.tableData().forEach((i: TableRow) => (i._active = false)); // THERE CAN BE ONLY ONE
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart && chart.tooltip.options.enabled) {
      const points = chart.series
        .reduce(
          (acc, s) => [
            ...acc,
            ...(s.points?.filter((p: Highcharts.Point | undefined) => {
              const series = p?.series || s;
              return (
                p != null && this.graphPredicate({ row, x: p.x, y: p.y, point: p, seriesName: series.name, series })
              );
            }) ?? []),
          ],
          [] as Highcharts.Point[],
        )
        ?.filter((p: Highcharts.Point | undefined) => p != null && p.y != null);
      if (points?.length) {
        points.forEach((p: Highcharts.Point | undefined) => p?.setState('hover'));
        this.isTooltipShared = chart.tooltip.options.shared;
        chart.tooltip.update({ shared: true });
        chart.tooltip.refresh(points);
      }
    }
  }

  async reset(row: TableRow) {
    if (!row) return;
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) {
      const points = chart.series
        .map((s) =>
          s.points?.find((p) =>
            this.graphPredicate({ row, x: p.x, y: p.y, point: p, seriesName: p.series?.name, series: p.series }),
          ),
        )
        .filter((p) => p != null && p.y != null);
      if (points?.length) {
        points.forEach((p: Highcharts.Point | undefined) => p?.setState());
      }
      chart.tooltip.update({ shared: this.isTooltipShared });
      chart.tooltip.hide();
    }
  }

  pointItemClick(): Highcharts.PointClickCallbackFunction {
    const self = this;
    return function (event: Highcharts.PointClickEventObject) {
      return self.toggleSeriesFocus(self, this.series.chart, this.series, event);
    };
  }

  legendItemClick(): Highcharts.SeriesLegendItemClickCallbackFunction {
    const self = this;
    return function (event: Highcharts.SeriesLegendItemClickEventObject) {
      return self.toggleSeriesFocus(self, this.chart, this, event);
    };
  }

  private toggleSeriesFocus(
    self: AbstractWidgetComponent,
    chart: Highcharts.Chart,
    series: Highcharts.Series,
    event: Highcharts.SeriesLegendItemClickEventObject | Highcharts.PointClickEventObject,
  ) {
    if (self.shiftDown) {
      if (self.chartState.state.length > 0) {
        // Restore previous state
        chart.series.forEach((s) => {
          const state = self.chartState.state.find((c) => c.id === (s.userOptions.id || s.name));
          if (state) s.setVisible(state.previous, false);
        });
      }
      if (self.chartState.selected !== (series.userOptions.id || series.name)) {
        // When shift is pressed, we hide every other series except the one clicked
        self.chartState.selected = series.userOptions.id || series.name;
        self.chartState.state = [];
        chart.series.forEach((s) => {
          self.chartState.state.push({ id: s.userOptions.id || s.name, previous: s.visible, current: s === series });
          s.setVisible(s === series, false);
        });
      } else {
        self.chartState.selected = undefined;
        self.chartState.state = [];
      }
      event.preventDefault();
      chart.redraw(true);
      return false;
    }
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  settingsClosed() {}

  /** Create a short hash of any object. Used to identify a row */
  private createHash(obj: any) {
    const { _active, ...rest } = obj;
    const string = JSON.stringify(rest);
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      const code = string.charCodeAt(i);
      hash = (hash << 5) - hash + code;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }

  // FIX PRINT LAYOUT ---------------------------------
  /**
   * Scale the graphs to fit an A4 page before print is called. This makes
   * sure the graph does not bleed out of the page. Highcharts seems persistant
   * on setting absolute widths for all things graph related.
   */

  @HostListener('window:beforeprint')
  async _beforePrint() {
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (chart as { [key: string]: any })['oldSize'] = [chart.chartWidth, chart.chartHeight];
      chart.setSize(this.isFullscreen() ? 760 : 380, 270, false);
      chart.reflow();
    }
  }

  /**
   * Scale back the graph to it's original size after print is done.
   */
  @HostListener('window:afterprint')
  async _afterPrint() {
    const chart: Highcharts.Chart | undefined = this.chart ? await firstValueFrom(this.chartRef$) : undefined;
    if (chart) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const [width, height] = (chart as { [key: string]: any })['oldSize'];
      chart.setSize(width, height, false);
      chart.reflow();
    }
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  onKey(event: KeyboardEvent) {
    this.shiftDown = event.shiftKey;
  }
}
